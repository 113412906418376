<template>
  <div class="body">
    <div class="card">
      <div class="card-item">
        <div class="card-title" style="color: #187fd1">vivo</div>
        <div
          class="button"
          style="color: #f56d34"
          @click="jump('https://dev.vivo.com.cn/manageCenter')"
        >
          开放平台
        </div>

        <div
          class="button"
          @click="
            jump(
              'https://minigame.vivo.com.cn/documents/#/lesson/open-ability/ad'
            )
          "
        >
          广告报错
        </div>

        <div
          class="button"
          @click="jump('https://adnet.vivo.com.cn/admin/home')"
        >
          广告联盟
        </div>

        <div
          class="button"
          @click="
            jump(
              'https://minigame.vivo.com.cn/documents/#/api/ad/interstitial-ad'
            )
          "
        >
          广告接入
        </div>

        <div
          class="button"
          @click="
            jump('https://minigame.vivo.com.cn/documents/#/download/debugger')
          "
        >
          调试器下载
        </div>
      </div>
      <div class="card-item">
        <div class="card-title" style="color: #56a35f">oppo</div>

        <div
          class="button"
          @click="
            jump(
              'https://ie-activity-cn.heytapimage.com/static/minigame/CN/docs/index.html#/question/qa/question-ad'
            )
          "
        >
          广告报错
        </div>

        <div
          class="button"
          @click="
            jump(
              'https://ie-activity-cn.heytapimage.com/static/minigame/CN/docs/index.html#/develop/ad/banner-ad'
            )
          "
        >
          广告接入
        </div>

        <div
          class="button"
          @click="
            jump(
              'https://ie-activity-cn.heytapimage.com/static/minigame/CN/docs/index.html#/develop/games/use'
            )
          "
        >
          调试器下载
        </div>
      </div>
      <div class="card-item">
        <div class="card-title" style="color: #fbb92b">快手</div>
        <div
          class="button"
          style="color: #f56d34"
          @click="
            jump('https://open.kuaishou.com/platform/controllCenter/game')
          "
        >
          开放平台
        </div>

        <div
          class="button"
          @click="
            jump(
              'https://mp.kuaishou.com/miniGameDocs/gameDev/start/devtool.html'
            )
          "
        >
          开发工具
        </div>

        <div
          class="button"
          @click="
            jump(
              'https://mp.kuaishou.com/miniGameDocs/gameDev/open-function/ad.html'
            )
          "
        >
          广告接入
        </div>

        <div
          class="button"
          style="color: #f56d34"
          @click="jump('https://ttdataplatform.zhejing.tech/dashboard/games')"
        >
          BP后台
        </div>

        <div
          class="button"
          style="color: #f56d34"
          @click="
            jump(
              'https://ttconfigplatform.zhejing.tech/templateManage/configure'
            )
          "
        >
          GM后台
        </div>

        <div class="button" @click="jump('https://developers.kuaishou.com/')">
          开发者社区
        </div>

        <div
          class="button"
          @click="
            jump(
              'https://docs.qingque.cn/d/home/eZQD6Ok0UHaQMpsYqI5JLrM2e?identityId=1oE3XPpOoOC#section=h.t7a4xp3vy09c'
            )
          "
        >
          技术文档
        </div>
      </div>
      <div class="card-item">
        <div class="card-title" style="color: #409be1">支付宝</div>

        <div
          class="button"
          @click="
            jump(
              'https://opendocs.alipay.com/mini-game/7334c5e7_my.createBannerAd?pathHash=cf342c4d'
            )
          "
        >
          广告接入
        </div>

        <div
          class="button"
          style="color: #f56d34"
          @click="jump('https://open.alipay.com/develop/manage')"
        >
          开放平台
        </div>

        <div
          class="button"
          @click="
            jump(
              'https://opendocs.alipay.com/mini-game/0dyacw?pathHash=4dcac011'
            )
          "
        >
          开发工具
        </div>
      </div>

      <div class="card-item">
        <div class="card-title" style="color: #409be1">bilibili</div>

        <div
          class="button"
          style="color: #f56d34"
          @click="jump('https://open.biligame.com')"
        >
          开发者平台
        </div>

        <div
          class="button"
          style="color: #f56d34"
          @click="
            jump(
              'https://qq9xw4i3ks.feishu.cn/docx/M6GjdUoa4oWuwCxkNSJcBIlBnah'
            )
          "
        >
          接入流程
        </div>

        <div
          class="button"
          @click="
            jump('https://miniapp.bilibili.com/small-game-doc/open/sidebar/')
          "
        >
          接口文档
        </div>
      </div>
      <div class="card-item">
        <div class="card-title" style="color: #c63644">其他</div>

        <div
          class="button"
          style="color: #f56d34"
          @click="jump('https://wxdataplatform.zhejing.tech/dashboard/games')"
        >
          微信BP
        </div>

        <div
          class="button"
          style="color: #f56d34"
          @click="jump('https://wxconfigplatform.zhejing.tech/')"
        >
          微信GM
        </div>
        <div
          class="button"
          @click="
            jump(
              'https://nqcq9c7h9mm.feishu.cn/wiki/L239wr4Pdi4kbZkUowjc5r4FnWc'
            )
          "
        >
          秒玩文档
        </div>

        <div
          class="button"
          @click="
            jump(
              'http://192.168.124.22:7456/web-mobile/web-mobile/index.html?sec-debug=1'
            )
          "
        >
          秒玩测试
        </div>

        <div
          class="button"
          @click="
            jump(
              'https://docs.cocos.com/creator/3.8/api/zh/class/View?id=enableAutoFullScreen'
            )
          "
        >
          cocos文档
        </div>

        <div class="button" @click="jump('https://obfuscator.io/#code')">
          js加密
        </div>

        <div
          class="button"
          style="color: #f56d34"
          @click="jump('https://developer.open-douyin.com/console?type=2')"
        >
          抖音后台
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    jump(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.body {
  margin-top: 3rem;
}
.card {
  margin-left: 5rem;
  margin-right: 5rem;
  display: flex;
  .card-item {
    width: 19rem;
    height: 40rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    .card-title {
      font-size: 24px;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.red-text {
  color: red;
}
.button {
  margin-bottom: 1rem;
  margin-top: 1rem;
  border: 1px solid #ffffff;
  padding: 13px;
  width: 6rem;
  background-color: rgba(105, 212, 228, 0.8);
  cursor: url(../../static/mouse/BLUE1/click.png), pointer;
  color: white;
}
</style>
