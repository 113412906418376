var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-item"},[_c('div',{staticClass:"card-title",staticStyle:{"color":"#187fd1"}},[_vm._v("vivo")]),_c('div',{staticClass:"button",staticStyle:{"color":"#f56d34"},on:{"click":function($event){return _vm.jump('https://dev.vivo.com.cn/manageCenter')}}},[_vm._v(" 开放平台 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump(
            'https://minigame.vivo.com.cn/documents/#/lesson/open-ability/ad'
          )}}},[_vm._v(" 广告报错 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump('https://adnet.vivo.com.cn/admin/home')}}},[_vm._v(" 广告联盟 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump(
            'https://minigame.vivo.com.cn/documents/#/api/ad/interstitial-ad'
          )}}},[_vm._v(" 广告接入 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump('https://minigame.vivo.com.cn/documents/#/download/debugger')}}},[_vm._v(" 调试器下载 ")])]),_c('div',{staticClass:"card-item"},[_c('div',{staticClass:"card-title",staticStyle:{"color":"#56a35f"}},[_vm._v("oppo")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump(
            'https://ie-activity-cn.heytapimage.com/static/minigame/CN/docs/index.html#/question/qa/question-ad'
          )}}},[_vm._v(" 广告报错 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump(
            'https://ie-activity-cn.heytapimage.com/static/minigame/CN/docs/index.html#/develop/ad/banner-ad'
          )}}},[_vm._v(" 广告接入 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump(
            'https://ie-activity-cn.heytapimage.com/static/minigame/CN/docs/index.html#/develop/games/use'
          )}}},[_vm._v(" 调试器下载 ")])]),_c('div',{staticClass:"card-item"},[_c('div',{staticClass:"card-title",staticStyle:{"color":"#fbb92b"}},[_vm._v("快手")]),_c('div',{staticClass:"button",staticStyle:{"color":"#f56d34"},on:{"click":function($event){return _vm.jump('https://open.kuaishou.com/platform/controllCenter/game')}}},[_vm._v(" 开放平台 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump(
            'https://mp.kuaishou.com/miniGameDocs/gameDev/start/devtool.html'
          )}}},[_vm._v(" 开发工具 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump(
            'https://mp.kuaishou.com/miniGameDocs/gameDev/open-function/ad.html'
          )}}},[_vm._v(" 广告接入 ")]),_c('div',{staticClass:"button",staticStyle:{"color":"#f56d34"},on:{"click":function($event){return _vm.jump('https://ttdataplatform.zhejing.tech/dashboard/games')}}},[_vm._v(" BP后台 ")]),_c('div',{staticClass:"button",staticStyle:{"color":"#f56d34"},on:{"click":function($event){return _vm.jump(
            'https://ttconfigplatform.zhejing.tech/templateManage/configure'
          )}}},[_vm._v(" GM后台 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump('https://developers.kuaishou.com/')}}},[_vm._v(" 开发者社区 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump(
            'https://docs.qingque.cn/d/home/eZQD6Ok0UHaQMpsYqI5JLrM2e?identityId=1oE3XPpOoOC#section=h.t7a4xp3vy09c'
          )}}},[_vm._v(" 技术文档 ")])]),_c('div',{staticClass:"card-item"},[_c('div',{staticClass:"card-title",staticStyle:{"color":"#409be1"}},[_vm._v("支付宝")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump(
            'https://opendocs.alipay.com/mini-game/7334c5e7_my.createBannerAd?pathHash=cf342c4d'
          )}}},[_vm._v(" 广告接入 ")]),_c('div',{staticClass:"button",staticStyle:{"color":"#f56d34"},on:{"click":function($event){return _vm.jump('https://open.alipay.com/develop/manage')}}},[_vm._v(" 开放平台 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump(
            'https://opendocs.alipay.com/mini-game/0dyacw?pathHash=4dcac011'
          )}}},[_vm._v(" 开发工具 ")])]),_c('div',{staticClass:"card-item"},[_c('div',{staticClass:"card-title",staticStyle:{"color":"#409be1"}},[_vm._v("bilibili")]),_c('div',{staticClass:"button",staticStyle:{"color":"#f56d34"},on:{"click":function($event){return _vm.jump('https://open.biligame.com')}}},[_vm._v(" 开发者平台 ")]),_c('div',{staticClass:"button",staticStyle:{"color":"#f56d34"},on:{"click":function($event){return _vm.jump(
            'https://qq9xw4i3ks.feishu.cn/docx/M6GjdUoa4oWuwCxkNSJcBIlBnah'
          )}}},[_vm._v(" 接入流程 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump('https://miniapp.bilibili.com/small-game-doc/open/sidebar/')}}},[_vm._v(" 接口文档 ")])]),_c('div',{staticClass:"card-item"},[_c('div',{staticClass:"card-title",staticStyle:{"color":"#c63644"}},[_vm._v("其他")]),_c('div',{staticClass:"button",staticStyle:{"color":"#f56d34"},on:{"click":function($event){return _vm.jump('https://wxdataplatform.zhejing.tech/dashboard/games')}}},[_vm._v(" 微信BP ")]),_c('div',{staticClass:"button",staticStyle:{"color":"#f56d34"},on:{"click":function($event){return _vm.jump('https://wxconfigplatform.zhejing.tech/')}}},[_vm._v(" 微信GM ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump(
            'https://nqcq9c7h9mm.feishu.cn/wiki/L239wr4Pdi4kbZkUowjc5r4FnWc'
          )}}},[_vm._v(" 秒玩文档 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump(
            'http://192.168.124.22:7456/web-mobile/web-mobile/index.html?sec-debug=1'
          )}}},[_vm._v(" 秒玩测试 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump(
            'https://docs.cocos.com/creator/3.8/api/zh/class/View?id=enableAutoFullScreen'
          )}}},[_vm._v(" cocos文档 ")]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.jump('https://obfuscator.io/#code')}}},[_vm._v(" js加密 ")]),_c('div',{staticClass:"button",staticStyle:{"color":"#f56d34"},on:{"click":function($event){return _vm.jump('https://developer.open-douyin.com/console?type=2')}}},[_vm._v(" 抖音后台 ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }